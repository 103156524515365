<template>
  <div class="wrapper">
    <CModal
      :show.sync="isModalOpen"
      :closeOnBackdrop="false"
      :centered="true"
      title=""
      color="secondary"
    >
    <CCard>
        <CCardBody>
        <CRow>
            <CCol lg="10">
                <CRow form class="form-group">
                    <CCol sm="5">
                      <Strong>앱 위변조 허용 앱 수</Strong>
                    </CCol>
                    <CCol v-if="allowAppCnt < 0" >
                    제한 없음
                    </CCol>
                    <CCol v-else>
                    {{allowAppCnt}} Apps
                    </CCol>

                </CRow>
                <CRow form class="form-group">
                  <CCol sm="5">
                    <Strong>앱 난독화 허용 앱 수</Strong>
                  </CCol>
                  <CCol v-if="allowObfuscationAppCnt < 0" >
                    제한 없음
                  </CCol>
                  <CCol v-else>
                    {{allowObfuscationAppCnt}} Apps
                  </CCol>

                </CRow>
                <CRow form class="form-group">
                    <CCol sm="5">
                      <Strong>만료일자</Strong>
                    </CCol>
                    <CCol>
                    {{$convertDateDay(expiredDate)}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="5">
                      <Strong>발행자</Strong>
                    </CCol>
                    <CCol>
                    {{issuer}}
                    </CCol>
                </CRow>
                <CRow form class="form-group">
                    <CCol sm="5">
                      <Strong>발행일자</Strong>
                    </CCol>
                    <CCol>
                    {{$convertDate(issuedDate)}}
                    </CCol>
                </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <template #header>
        <h6 class="modal-title">라이선스 정보</h6>
        <CButtonClose @click="$emit('close-modal')" class="text-white"/>
        </template>
        <template #footer>
        <CButton class="btn btn-primary" @click="$emit('close-modal')">닫기</CButton>
    </template>
    </CModal>
  </div>

</template>

<script>
export default {
    name: 'licenseInfo',
    data () {
        return {
            isModalOpen:true,
            allowAppCnt:'',
            allowObfuscationAppCnt:'',
            issuer:'',
            issuedDate:'',
            expiredDate:'',
            siteName:''
        }
    },
    props: {

    },
    mounted(){
        this.searchLicenseInfo()
    },
    methods: {
        async searchLicenseInfo(){

          this.loading=true
          try {
              await this.$store.dispatch('license/getLicenseInfo')
              var licenseInfo = this.$store.state.license.licenseInfo
              this.allowAppCnt = licenseInfo.allowAppCnt
              this.allowObfuscationAppCnt = licenseInfo.allowObfuscationAppCnt  // obfuscation app count is not available in the API response, so I used the same value as allowAppCnt for this example.
              this.issuer = licenseInfo.issuer
              this.issuedDate = licenseInfo.issuedDate
              this.expiredDate = licenseInfo.expiredDate
              this.siteName = licenseInfo.siteName
              this.loading=false
          } catch(error){
              this.$response(error)
              this.loading=false
          }
        },
        detailItem(){
            this.isOpenModal=true
        },
    }
}
</script>
