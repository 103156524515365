<template>
  <CBreadcrumb v-bind="props">
  </CBreadcrumb>
</template>

<script>
export default {
  name: 'CBreadCrumb',
  components: {
  },
  props: {
    addClasses: [String, Array, Object],
    addLinkClasses: [String, Array, Object],
    addLastItemClasses: [String, Array, Object]
  },
  computed: {
    items () {
      const routes = this.$route.matched.filter(route => {
        return route.name || (route.meta && route.meta.title)
      })
      return routes.map(route => {
        const meta = route.meta || {}
        return {
          to: route,
          text: meta.title || route.name
        }
      })
    },
    props () {
      return {
        items: this.items,
        addClasses: this.addClasses,
        addLinkClasses: this.addLinkClasses,
        addLastItemClasses: this.addLastItemClasses
      }
    }
  }
}
</script>