import loginStore from '@/store/modules/loginStore'

function searchMenuHiddenState(menuKey){
  let readPermissionList = loginStore.state.menuReadPermissionList;

  switch(menuKey){
    case '101':
        if(readPermissionList.includes('103') || readPermissionList.includes('104')){
          return false;
        }
      break;
    case '201':
      if(readPermissionList.includes('202') || readPermissionList.includes('203')){
        return false;
      }
      break;
    case '301':
      if(readPermissionList.includes('302') || readPermissionList.includes('303')){
        return false;
      }
      break;

    default:
      if(readPermissionList.includes(menuKey)){
        return false;
      }
      break;

  }
  return true;
}
const navItems = [

  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: '대시보드',
        to: '/dashboard',
        icon: 'cil-chart-pie',
        _attrs:{
          //hidden : searchMenuHiddenState('102')
          }
      },

      {
        _name: 'CSidebarNavTitle',
        _children: ['모니터링'],
        _attrs:{
          hidden : searchMenuHiddenState('101')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '위변조 검증 목록',
        to: '/monitoring/validationLog/validationLogList',
        icon: 'cil-list',
        _attrs:{
          hidden : searchMenuHiddenState('103')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '감사 로그',
        to: '/monitoring/audit/auditLogList',
        icon: 'cil-chart',
        _attrs:{
          hidden : searchMenuHiddenState('104')
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['앱 관리'],
        _attrs:{
          hidden : searchMenuHiddenState('201')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '앱 관리',
        to: '/app/appMng/appList',
        icon: 'cil-applications',
        _attrs:{
          hidden : searchMenuHiddenState('202')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '앱 난독화',
        to: '/app/appMng/appObfuscation',
        icon: 'cil-shield-alt',
        _attrs:{
          hidden : searchMenuHiddenState('203')
        }
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['관리자계정'],
        _attrs:{
          hidden : searchMenuHiddenState('301')
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '권한 그룹관리',
        to: '/admin/permissionGroup/permissionGroupList',
        icon: 'cil-lock-locked',
        _attrs:{
          hidden : searchMenuHiddenState('302')
        }
      },{
        _name: 'CSidebarNavItem',
        name: '계정관리',
        to: '/admin/account/accountList',
        icon: 'cil-user',
        _attrs:{
          hidden : searchMenuHiddenState('303')
        }
      }
    ]
  }
]

export default navItems
