<template>
<div>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <img class="c-sidebar-brand-full" alt="Home" src="@/assets/img/mainLogo.png"   size="custom-size" :height="35" viewBox="0 0 556 134">
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          대시보드
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <!-- <CHeaderNavItem class="px-3 c-d-legacy-none">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun"/>
          <CIcon v-else name="cil-moon"/>
        </button>
      </CHeaderNavItem> -->
      <CHeaderNavItem class="px-3 c-d-legacy-none">
        <div>
         세션 만료시간: {{minutes}}분 {{seconds}}초
        </div>
      </CHeaderNavItem>
      <CHeaderNavItem>
        <button v-c-tooltip="'내정보'" class="c-header-nav-btn"
          @click="() => this.$router.push({path: '/myAccountInfo'})  ">
           <CIcon size="xl" name="cil-user" />
        </button>
      </CHeaderNavItem> 
       <CHeaderNavItem >
        <button v-c-tooltip="'라이선스 정보'"
          @click="isOpenLicenseModal=true"
          class="c-header-nav-btn">
            <CIcon size="xl" name="cil-info"/>
        </button>
      </CHeaderNavItem> 
      <CDropdown
        v-if="$isSuperAdmin()"
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
      >
        <template #toggler>
          <CHeaderNavItem>
            <button v-c-tooltip="'설정'" class="c-header-nav-btn">
              <CIcon size="xl" name="cil-settings" />
            </button>
          </CHeaderNavItem> 
        </template>
        <CDropdownHeader tag="div" class="text-center" color="light">
          <strong>설정</strong>
        </CDropdownHeader>
        <CDropdownItem>
        <CLink :to="`/logMngInfo`" >
          <!-- <CIcon name="cil-user" /> 로그성데이터관리 -->
          로그성 데이터 관리
        </CLink>
        </CDropdownItem>
        <!-- <CDropdownItem @click="isOpenLicenseModal=true">
          <CIcon name="cil-info" /> 라이선스 정보
        </CDropdownItem>
        <CDropdownDivider></CDropdownDivider>
        <CDropdownItem v-on:click="logout()">
          <CIcon name="cil-account-logout" /> 로그아웃
        </CDropdownItem> -->
      </CDropdown>
       <CHeaderNavItem>
        <button v-c-tooltip="'로그아웃'"
          @click="() =>this.$store.dispatch('loginStore/logout')"
          class="c-header-nav-btn">
            <CIcon size="xl" name="cil-account-logout"/>
        </button>
      </CHeaderNavItem> 
    </CHeaderNav>
    <CSubheader class="px-3">
        <CBreadCrumb class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
  <LicenseInfoModal v-if="isOpenLicenseModal" @close-modal="isOpenLicenseModal=false"/>
</div>
</template>

<script>
import CBreadCrumb from './CBreadCrumb'
import { minuteCounter, secondCounter } from '@/js/common/timerUtil.js';
import LicenseInfoModal from "@/views/license/LicenseInfoModal.vue";

export default {
  name: 'TheHeader',
  components: {
    LicenseInfoModal,
    CBreadCrumb
  },
  data () {
    return {
      isOpenLicenseModal : false,
      expireDate:null,
      remainingDate: new Date().getTime(),
      timer: '',
      timestampEnd:null
    }
  },
  computed:{
    minutes() {
      return minuteCounter(this.remainingDate);
    },
    seconds() {
      return secondCounter(this.remainingDate);
    }
  },
  created(){
    const date = new Date() 
    const expireDate = new Date(localStorage.getItem("ExpireTime"))
    this.timestampEnd = expireDate.getTime()
    this.updateTime();
    this.timer = setInterval(this.updateTime, 1000);
  },
  mounted(){
  },
  methods:{
    updateTime() {
      this.remainingDate = this.timestampEnd - new Date().getTime();
    },
  },
  destroyed(){
    clearInterval(this.timer);
  }
}
</script>
