<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://raoncorp.com" target="_blank">Copyright &copy; Raonsecure.</a>
      <span class="ml-1">All rights reserved</span>
    </div>
    <div class="mfs-auto">
      <span class="text-light" >ADMIN VERSION </span>
      {{coreVersion}}
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  mounted:function(){
      this.getServerVersion() 
    },
  data(){
      return {
        coreVersion: ''
      }
    },
  methods:{
    async getServerVersion(){
      try{
        let response = await this.$store.dispatch("sysConfig/getSysConfig")
        if(response.status == 200){
            this.coreVersion = response.data.coreVersion
        }
      }catch(error){
        alert(error.errorMessage)
      }
    }
  }
}
</script>
